.custom-header{
    background-color: white;
    padding: 0 20px;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 125;
    box-shadow: 0 0 10px var(--black-200);
}

.custom-header .ch-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.custom-header .ch-inner .ch-left{
    display: flex;
    gap: 15px;
}


/* Header Logo Start */
.custom-header .ch-inner .ch-left .ch-logo-bx{
    display: block;
    width: 240px;
}

.custom-header .ch-inner .ch-left .ch-logo-bx img.main-logo{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.custom-header .ch-inner .ch-left .ch-logo-bx img.main-logo2{
    display: none;
}
/* Header Logo End */

/* Header lightmode darkmode Start*/

.mode-icon-box {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
    color: var(--black-700);
    border: none;
    transition: all 0.3s ease-in-out;
}

.mode-icon-box:hover {
    color: var(--black);
    background-color: var(--black-100);
}

.mode-icon-box svg {
    width: 25px;
    height: 25px;
}

/* .mode-icon-box:hover {
    background-color: #d3d3d3;
} */

@media screen and (max-width:380px) {

    .custom-header {
        padding: 0 15px;
    }
    .custom-header .ch-inner .ch-left {
        gap: 10px;
    }

    .custom-header .ch-inner .ch-left .ch-logo-bx {
        width: 185px;
    }

}


/* Header lightmode darkmode End*/

/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    display: flex;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #e5e7eb;
}

.menu-icon-bx:hover{
    background-color: #d3d3d3;
}

.menu-icon-bx.active{
    background-color: rgb(244 234 231);
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 2px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.8;
    background-color: var(--black);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.menu-icon-bx span:nth-child(2) {
    width: 60%;
    margin-right: auto;
}


.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */