html {
    scroll-behavior: initial !important;
}

.por {
    position: relative;
}

* {
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a,
a:hover {
    text-decoration: none;
    color: var(--black);
}

.common-word-wrap {
    word-break: break-word;
}

/* --- Website Fonts --- */
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-display: swap;
    font-weight: 100 900;
    font-style: normal;
}

/* --- Body tag Css --- */
body {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-s-gray);
    background-color: var(--light-s-gray);
}

.overflowhidden,
body.overflowhidden {
    overflow: hidden;
}

/* --- Bootsrep container Css Start --- */
.container-lg.cl-custome {
    max-width: 1140px;
}

.container-lg.cl-custome2 {
    max-width: 1200px;
}

.container-lg.cl-custome3 {
    max-width: 1920px;
    padding: 0;
}

/* --- Bootsrep container Css End --- */

/* --- Bootsrep Modal Start --- */
.modal {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.custom-content .modal-content {
    background-color: var(--white);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.custom-content.cc-mw396px .modal-content {
    max-width: 396px;
    margin: auto;
}

.custom-modal-header {
    position: -webkit-sticky;
    position: sticky;
    padding: 20px 15px 20px;
}

.custom-modal-header .cmh-lable {
    font-size: 20px;
    font-weight: 600;
    color: var(--black);
    text-align: center;
}

.custom-modal-header .cmh-sub-lable {
    color: var(--black-700);
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
}

.custom-modal-body {
    padding: 0 15px;
}

/* --- Bootsrep Modal End --- */


/* --- Common Data Bx Start --- */
.common-data-bx {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #fdfdfd;
    border: 1px solid var(--black-100);
}

.common-data-bx .cdb-item {
    display: flex;
    justify-content: space-between;
    color: var(--black);
}

.common-data-bx .cdb-item .cdb-label {
    color: var(--black-400);
    font-size: 14px;
    font-weight: 600;
}

.common-data-bx .cdb-item .cdb-data {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    width: fit-content;
}

.common-data-bx .cdb-item .cdb-data .cdb-icon {
    align-items: center;
    border-radius: 50px;
    color: black;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    padding: 6px;
    position: absolute;
    right: 0;
    top: -5px;
    transition: all .3s ease-in-out;
    width: 30px;
}

.common-data-bx .cdb-item .cdb-data .cdb-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.common-data-bx .cdb-item .cdb-data .cdb-icon:hover {
    background-color: rgb(244 234 231);
    color: var(--black);
}

/* --- Common Data Bx End --- */


/* --- Custome Wrapper start */
.custome-wrapper {
    padding: 15px;
    padding-left: calc(88px + 15px);
    padding-bottom: 50px;
}

@media screen and (max-width:992px) {
    .custome-wrapper {
        padding: 15px;
        padding-bottom: 50px;
    }
}

/* --- Custome Wrapper End */

/* --- Common Color Status Bx Start --- */
.status-yellow {
    color: #913515;
}

.status-green {
    color: #00ab61;
}

.status-red {
    color: rgb(255, 0, 0)
}

.status-yellow2 {
    background-color: #fdf6b2;
    color: #913515;
}

.status-green2 {
    background-color: #def7ec;
    color: #00ab61;
}

.status-red2 {
    background-color: #fde8e8;
    color: rgb(255, 0, 0)
}
.status-yellow2 {
    background-color: #eab308;
    color: #8e4b10;
}

/* --- Common Color Status Bx End --- */

/* --- Common Box Start --- */
.common-box {
    background-color: var(--white);
    border-radius: 12px;
    padding: 15px;
    position: relative
}

.common-box.cb-color-two {
    background-color: rgb(244 234 231);
}

.common-box-heading {
    color: var(--black);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.common-box-heading span.cbh-color {
    color: #f6a500;
}

.common-box-heading svg {
    color: var(--electricblue);
    width: 28px;
    height: 28px;
    margin-right: 8px;
    position: relative;
    top: -2px;
}

.common-box-heading.cbh-small {
    font-size: 16px;
}

.common-box-heading.cbh-small svg {
    width: 20px;
    height: 20px;
}

.common-box-sub-heading {
    color: var(--black-700);
    font-size: 16px;
    font-weight: 500;
}

.common-section-heading {
    color: var(--black-700);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
}

.common-box-highlight {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    -webkit-text-fill-color: #0000;
    background: linear-gradient(45deg, #f6a500, #913515);
    -webkit-background-clip: text;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 5px;
}

.common-section-separator {
    margin: 15px 0;
    width: 100%;
    height: 2px;
    background-color: var(--black-100);
}

.common-sticky {
    position: sticky;
    top: 85px;
}

/*--deshboard filter--*/

.dashbord-filter .common-box-heading.cbh-small {
    display: flex;
    align-items: center;
    color: var(--black);
}

.dashbord-filter .common-box-heading.cbh-small svg {
    width: 28px;
    height: 28px;
}

@media screen and (max-width:576px) {
    .common-box-heading {
        font-size: 20px;
    }
}

/* --- Common Box End --- */

/*  */
.common-box-info {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 7px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    padding: 6px;
    color: var(--black);
    transition: all 0.3s ease-in-out;
}

.common-box-info:hover {
    color: var(--black);
    background-color: rgb(244 234 231);
}

.common-box-info svg {
    width: 30px;
    height: 30px;
}

/*  */

/* --- Common Button Css Start --- */
.cbi-gap-10px {
    gap: 10px;
}

.common-btn-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    height: 36px;
    line-height: 36px;
    padding: 0 15px;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #000;
    font-weight: 500;
    letter-spacing: 1px;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item span {
    white-space: nowrap;
}

.common-btn-item svg {
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.cbi-small {
    font-size: 12px;
    height: 32px;
    min-width: auto;
    padding: 0 10px;
}           

.common-btn-item.cbi-fill {
    color: white;
    border-color: #e18f04;
    background-image: linear-gradient(45deg, #f6a500, #913515);
    background-size: 150%;
    background-position: left center;
}

.common-btn-item.cbi-outline {      
    color: #000;
    border-color: #933819;
}       

.common-btn-item.cbi-fill.meta-trader {
    background-color: rgb(244 234 231);
    background-image: none;
    color: var(--black);
    border-color: rgb(244 234 231);
    cursor: default;
}

@media only screen and (min-width: 768px) {

    .common-btn-item.cbi-fill:hover {
        background-position: right center;
    }

    .common-btn-item.cbi-outline:hover {
        color: white;
        background-color: #e18f04;
        border-color: #e18f04;
    }

}

@media only screen and (max-width: 392px) {

    .common-btn-item {
        font-size: 12px;
        height: 32px;
        min-width: auto;
        padding: 0 10px;
    }

}


/* --- Breadcrumb Css Start --- */
.breadcrumb-main-bx {
    margin-bottom: 20px;
}

.breadcrumb-heading-bx {
    font-size: 16px;
    color: var(--black);
    font-weight: 600;
    white-space: nowrap;
}

.breadcrumb-heading-bx svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
    margin-right: 5px;
}


.breadcrumb-bx {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    margin-top: 8px;
}

.breadcrumb-bx .breadcrumb-link {
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    color: var(--black-900);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active {
    font-weight: 600;
    color: var(--black-400);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover {
    color: #913515;
    cursor: pointer;
}

/* --- Breadcrumb Css End --- */



/* --- Common Tabs Bx Start --- */
.common-tabs-bx {
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 1px;
    position: relative;
    white-space: nowrap;
}

.common-tabs-bx::-webkit-scrollbar {
    display: none;
}

.common-tabs-bx::after {
    background-color: var(--black-100);
    content: " ";
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(100% - 1px);
    width: 100%;
}

.common-tabs-bx .ctb-item {
    color: var(--black-700);
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 15px;
    position: relative;
    /* transition: all 0.3s ease-in-out; */
}

.common-tabs-bx .ctb-item::after {
    content: " ";
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    width: 100%;
    height: 2px;
    opacity: 0;
    z-index: 1;
    border-radius: 50px 50px 0 0;
    background-color: var(--black-100);
    transition: opacity 0.3s ease-in-out;
}

.common-tabs-bx .ctb-item:hover,
.common-tabs-bx .ctb-item.active {
    color: var(--black);
}

.common-tabs-bx .ctb-item:hover::after {
    opacity: 1;
}

.common-tabs-bx .ctb-item.active::after {
    opacity: 1;
    background: linear-gradient(45deg, #f6a500, #913515);
}


/* common-tabs-bx style 2 */
.common-tabs-bx.ctb-style-2 {
    padding: 0;
}

.common-tabs-bx.ctb-style-2::after {
    display: none;
}

.common-tabs-bx.ctb-style-2 .ctb-item {
    font-size: 16px;
    padding: 8px 14px;
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
    margin: 0 5px;
    border: none;
    background-color: var(--light-s-gray);
}

.common-tabs-bx.ctb-style-2 .ctb-item.active {
    color: white;
}

.common-tabs-bx.ctb-style-2 .ctb-item::after {
    top: 0;
    height: 100%;
    border-radius: 0;
    z-index: -1;
}

.common-tabs-bx.ctb-style-2 .ctb-item.active::after {
    background: linear-gradient(45deg, #f6a500, #913515);
}

@media screen and (max-width:576px) {
    .common-tabs-bx .ctb-item {
        font-size: 14px;
    }
}

/* --- Common Tabs Bx End --- */


/* --- Common progesrr --- */
.common-progress-bar {
    height: 7px;
    width: 100%;
    background-color: #d1d5db;
    overflow: hidden;
    border-radius: 50px;
}

.common-progress-bar .common-progress-inner {
    position: relative;
    height: 100%;
    border-radius: 50px;
    background-color: #913515;
    transition: all 0.3s ease-in-out;
}

.common-progress-bar .common-progress-inner .common-progress-circle {
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    padding: 6px;
    border: 2px solid #913515;
    background-color: rgb(244 234 231);
    border-radius: 50%;
    box-shadow: 0 0 5px #00000038;
}

/* ---  --- */


/* --- Custome Table Css Start --- */
.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table-responsive {
    border-radius: 10px;
    border: 1px solid var(--black-100);
}

.custom-table tbody tr {
    border-top: 1px solid var(--black-100);
}

.custom-table thead {
    background-color: var(--light-s-gray);
}

.custom-table thead tr th {
    font-weight: 600;
    color: var(--black);
    font-size: 14px;
    white-space: nowrap;
    padding: 10px 15px;
    cursor: pointer;
}


/* Sorting Icons css start */
.custom-table thead tr th .th-sort-icons {
    display: inline-flex;
    flex-direction: column;
    margin-left: 2px;
    position: relative;
    top: -2px;
    color: var(--celeste-600);
}

.custom-table thead tr th:hover .th-sort-icons,
.custom-table thead tr th.active .th-sort-icons {
    color: var(--celeste);
}

.custom-table thead tr th .th-sort-icons svg {
    width: 12px;
    height: 12px;
}

.custom-table thead tr th .th-sort-icons svg:nth-child(1) {
    margin-bottom: -2px;
}

.custom-table thead tr th .th-sort-icons svg:nth-child(2) {
    margin-top: -2px;
}

.custom-table thead tr th.asc .th-sort-icons svg:nth-child(1),
.custom-table thead tr th.desc .th-sort-icons svg:nth-child(2) {
    color: var(--electricblue);
}

/* Sorting Icons css end */


.custom-table tbody tr td {
    position: relative;
    padding: 12px 15px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-900);
    white-space: nowrap;
    line-height: normal;
}


.custom-table tbody tr:not(.light-yellow, .light-orang, .light-red, .light-gray, .light-green):nth-child(even) {
    background-color: #fbfbfb;
}

.custom-table tbody tr:not(.light-yellow, .light-orang, .light-gray, .light-red, .light-green):hover {
    background-color: #f3f3f3;
}



.custom-table tbody tr td .td-admin-note {
    /* -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    margin: 0;
    overflow: hidden; */
    width: 200px;
}


/* .custom-table tbody tr td div:not(.td-status),
.custom-table tbody tr td div.country-td{
    color: var(--celeste-600);
    margin-top: 5px;
    font-size: 12px;
}

.custom-table tbody tr td div.country-td{
    display: flex;
    align-items: center;
    gap: 3px;
}

.custom-table tbody tr td div.country-td img{
    width: 18px;
    height: 12px;
    object-position: center;
    object-fit: contain;
} */

/* .custom-table tbody tr td a{
    display: flex;
    width: 30px;
    height: 30px;
    padding: 7px;
    border-radius: 50%;
    background-color: var(--celeste-100);
    transition: all 0.3s ease-in-out;
}
    
.custom-table tbody tr td a:hover{
    background-color: var(--electricblue);
    color: var(--darkblue);
}
    
.custom-table tbody tr td a svg{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
} */

.custom-table tbody tr td.td-wrap {
    text-wrap: wrap;
}

.custom-table tbody tr td .td-status {
    align-items: center;
    display: flex;
    gap: 5px;
}

.custom-table tbody tr td .td-status svg {
    width: 20px;
    height: 20px;
}

.custom-table tbody tr td .td-status.td-yellow {
    color: #913515;
}

.custom-table tbody tr td .td-status.td-yellow2 {
    background-color: #fdf6b2;
    color: #913515;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
}

.custom-table tbody tr td .td-status.td-red {
    color: rgb(255, 0, 0);
}

.custom-table tbody tr td .td-status.td-red2 {
    background-color: #fde8e8;
    color: rgb(255, 0, 0);
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
}

.custom-table tbody tr td .td-status.td-green {
    color: #00ab61;
}

.custom-table tbody tr td .td-status.td-green2 {
    background-color: #def7ec;
    color: #00ab61;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
}

/*---start pagenation --*/

.pagenation-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 3px 10px;
}

.pagenation-filter .pf-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pagenation-filter .pf-left .pf-label {
    font-size: 14px;
    font-weight: 500;
}

.pagenation-filter .pf-left .pf-input input {
    height: auto;
    padding: 6px 3px;
    text-align: center;
    width: 46px;
    border-radius: 4px;
    border: 1px solid #00000038;
    line-height: 15px;
    outline: none;
    /* color: #000; */
    background: #fbfbfb;
    font-size: 12px;
}

.pagenation-filter .pf-right .pf-pagination {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pagenation-filter .pf-right .pf-btn {
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
}

.pagenation-filter .pf-right .pf-btn:hover {
    color: #000;
    background: rgb(244 234 231);
}

.pagenation-filter .pf-right .pf-text div:first-child {
    color: #00ab61;
}

.pagenation-filter .pf-right .pf-text span {
    padding: 0 3px;
}

.pagenation-filter .pf-right .pf-text {
    display: flex;
}

/*-- End Pagenation --*/


/*--start disclaimer --*/
.legal-content.disclaimer {
    padding: 0;
}

.legal-content.disclaimer .legal-heading {
    font-size: 16px;
}

/*-- End Disclaimer --*/


/* --- ct-style-2 --- */
.ctr-style-2 {
    border: none;
}

.ct-style-2 {
    border-collapse: initial;
    border-spacing: 0 8px;
}

.ct-style-2 thead {
    background: none;
}

.ct-style-2 thead tr th {
    color: var(--black-400);
    font-weight: 700;
}

.ct-style-2 tbody tr td {
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: var(--black-100);
    font-weight: 600;
}

.ct-style-2 tbody tr td:first-child {
    border-left-width: 1px;
    border-radius: 8px 0 0 8px;
}

.ct-style-2 tbody tr td:last-child {
    border-right-width: 1px;
    border-radius: 0 8px 8px 0;
}



.td-user-rank {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 240px;
}

.td-user-rank .td-u-sr {
    font-size: 28px;
    color: var(--black-400);
    font-weight: 700;
    width: 32px;
    text-align: center;
}

.td-user-rank img.td-u-img {
    width: 56px;
    height: 56px;
    object-fit: contain;
    object-position: center;
}

.td-user-rank .td-u-name {
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
}

.td-user-rank .td-u-name span {
    color: var(--black-700);
    display: block;
    font-size: 14px;
    margin-top: 3px;
}



/*--calender Table ---*/

.light-yellow {
    background: var(--light-yellow);
}

.light-red {
    background: var(--light-red);
}

.light-orang {
    background: var(--light-orang);
}

.light-gray {
    background: var(--light-gray);
}

.light-green {
    background: var(--light-green);
}

.light-orang .currency .flag svg,
.light-orang .currency .c-big {
    stroke: #f97316;
    color: #f97316;
}

.light-yellow .currency .flag svg,
.light-yellow .currency .c-big {
    stroke: #eab308;
    color: #eab308;
}

.light-gray .currency .flag svg,
.light-gray .currency .c-big {
    stroke: #6b7280;
    color: #6b7280;
}

.light-red .currency .flag svg,
.light-red .currency .c-big {
    stroke: #ff0000;
    color: #ff0000;
}

.light-green .currency .flag svg,
.light-green .currency .c-big {
    stroke: #74b181;
    color: #74b181;
}

.d-red svg {
    color: #e02424;
}

.calendar-table .custom-table .currency {
    display: flex;
    gap: 16px;
    align-items: center;
}

.calendar-table .custom-table tr td {
    font-weight: 600;
}

.calendar-table tr td span>svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
}

.calendar-table .c-big {
    font-size: 28px;
    font-weight: 700;
}

.calendar-table .c-small {
    color: #1f2937;
    font-weight: 700;
}

.calendar-table .custom-table tbody tr {
    border: none;
    cursor: pointer;
}

.custom-table tbody tr:not(.light-yellow, .light-orang, .light-gray, .light-red, .light-green):hover {
    background-color: #f3f3f3;
}

.custom-table-responsive.calendar-table {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


/*-- table accordatin css start --*/

.cta-box {
    /* width: 70px; */
    border-right: 1px solid var(--black-100);
    padding-right: 30px;
}   

.cta-box:last-child {
    border: none;
}
            
.cta-inner-detail {
    display: flex;
    gap: 26px;
    padding-bottom: 15px;
}

.cta-box .cta-box-title {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
}

.cta-box .cta-box-text {
    font-size: 24px;
    font-weight: 800;
}

.cta-inner .common-box-sub-heading {
    white-space: normal;
    line-height: 24px;
    font-weight: 400;
    padding-bottom: 15px;
}

@media screen and (max-width:767px)   {
    .cta-inner .common-box-sub-heading { 
        font-size: 14px;
    }
}

/*-- table accordatin css End --*/

/**---fliter section--**/

.main_filter_section .common-tabs-bx.ctb-style-2 .ctb-item {
    margin: 0 10px 0 0;
    font-size: 15px;
    font-weight: 400;
    border-radius: 12px;
}

.main_filter_section label,
.main_filter_section .sgr-outer-label {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 5px;
}

.currency-filter .signinup-group select {
    background: transparent;
    border-radius: 12px;
    border: 2px solid #d1d5db;
}

.currency-filter .signinup-group {
    margin: 0px 0 12px;
}

.fliter-tab label {
    display: block;
}

.filter-btn .btn {
    margin-right: 15px;
    margin-top: 5px;
    border-radius: 12px;
    margin-bottom: 10px;
    font-size: 15px;
    box-shadow: none;
}


.filter-btn .btn.red.active {
    background: #dc2626;
    border-color: #dc2626;
    color:#fff;
}

.filter-btn .btn.red.inactive {
    background: #fef0f0;
    border-color: #fef0f0;
    color: #000;
}



.filter-btn .btn.orange.inactive {
    background: #fff6ea;
    border-color: #fff6ea;
    color: #f97316;
}  

.filter-btn .btn.orange.active {
    background: #f97316;
    border-color: #f97316;
    color: #fff;
}


.filter-btn .btn.yellow.inactive {
    background-color: #fef9c380;
    border-color: #fef9c380;
    color: #eab308;
}

.filter-btn .btn.yellow.active {
    background: #eab308;
    border-color: #eab308;
    color: #fff;
}

  
.filter-btn .btn.darkgray.inactive {
    background-color: #c7c7c780;
    border-color: #f3f4f680;
    color: #6b7280;
}


.filter-btn .btn.darkgray.active {
    background: #6b7280;
    border-color: #6b7280;
    color: #fff;
}

.filter-btn .btn.green.active {
    background: #19e346;
    border-color: #19e346;
    color: #fff;
}


.filter-btn .btn.green.inactive {
    background: #b4f3c2;
    border-color: #b4f3c2;
    color: #21c145;
}


@media screen and (min-width:577px) {

    .filter-btn .btn.red.inactive:hover {
        background-color: #dc262666;
        border-color: #f1a8a8;
        color: #000;
    }
    
    .filter-btn .btn.darkgray.inactive:hover {
        background-color: #6b728066;
        border-color: #c4c7cc;
        color: #6b7280;
    }

    .filter-btn .btn.yellow.inactive:hover {
        background-color: #eab30866;
        border-color: #f7e19c;
        color: #eab308;
    }

    .filter-btn .btn.orange.inactive:hover {
        background-color: #f9731666;
        border-color: #fdc7a2;
        color: #f97316;
    }

    .filter-btn .btn.green.inactive:hover {
        background: #7fd792;
        border-color: #90dba0;
        color: #21c145;  
    }
}


@media screen and (max-width:576px) {
    .filter-btn .btn.red:active {
        background: #dc2626;
        border-color: #dc2626;
        color: #fff;
    }

    .filter-btn .btn.darkgray:active {
        background: #6b7280;
        border-color: #6b7280;
        color: #fff;
    }

    .filter-btn .btn.yellow:active {
        background: #eab308;
        border-color: #eab308;
        color: #fff;
    }

    .filter-btn .btn.orange:active {
        background: #f97316;
        border-color: #f97316;
        color: #fff;
    }

    .filter-btn .btn.green.active {
        background: #19e346;
        border-color: #19e346;
        color: #fff;
    }
}


@media screen and (max-width:767px) { 
    .filter-btn .btn {
        margin-right: 5px;
        border-radius: 12px;
        font-size: 13px;
    }
    
}


/* --- Custome Table Css End --- */



/* --- Notice Start--- */
.legal-content {
    padding: 0 15px 25px;
}

.legal-content .legal-heading {
    font-size: 13px;
    color: var(--black);
    font-weight: 700;
    line-height: 1.5;
}

.legal-content ul li,
.legal-content p {
    font-size: 13px;
    line-height: 1.7;
    margin: 0;
    margin-top: 3px;
    color: var(--black-900);
    font-weight: 500;
    text-align: left;
}

.legal-content ul.font-16 li {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.6;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin: 0;
    margin-left: 10px;
}


.legal-content ul.ul-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}


.legal-content.legal-notes>div,
.legal-content.legal-notes>ul>li {
    font-size: 16px;
    font-weight: 500;
}

.legal-content .legal-heading.ul-red {
    color: rgb(255, 0, 0);
}

.legal-content ul.ul-red li {
    color: rgb(255, 0, 0);
    font-weight: 400;
    line-height: 25px;
    margin-top: 5px;
}

.signinup-group.center {
    display: flex;
    justify-content: center;
}

@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}


.legal-content ul li a,
.legal-content p a {
    font-weight: 700;
    text-decoration: none;
    color: rgb(0, 109, 209);
}

.legal-content ul a:hover,
.legal-content p a:hover {
    text-decoration: underline;
}

.legal-content ul strong,
.legal-content p strong {
    font-weight: 700;
    color: black;
}

/* --- Notice End --- */



/* --- Close Btn Css Start --- */
.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--black);
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.close-icon:hover {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
}

@media screen and (min-width:700px) {
    .close-icon:hover {
        background-color: var(--light-s-gray);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active {
        background-color: var(--light-s-gray);
    }
}

/* --- Close Btn Css End --- */

/* --- Common back btn start --- */
.common-back-btn {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 8px;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.common-back-btn:hover {
    opacity: 1;
    background-color: var(--light-s-gray);
}

.common-back-btn svg {
    width: 20px;
    stroke-width: 2px;
    height: 20px;
}

.common-back-btn.cbb-absolute {
    position: absolute;
    left: 15px;
    top: 15px;
}

/* --- Common back btn End --- */



/* --- welcome box start --- */
.welcome-bx {
    position: relative;
}

.welcome-bx-inner {
    padding: 15px;
    display: flex;
    flex-direction: column;
    max-width: 420px;
}

.welcome-bx .welcome-heading {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 15px;
    -webkit-text-fill-color: #0000;
    background: -webkit-linear-gradient(45deg, #f6a500, #913515);
    background: -moz-linear-gradient(45deg, #f6a500, #913515);
    background: -o-linear-gradient(45deg, #f6a500, #913515);
    background: linear-gradient(45deg, #f6a500, #913515);
    -webkit-background-clip: text;
    width: fit-content;
}

.welcome-info .welcome-info-heading {
    color: var(--black);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.welcome-info .welcome-info-text {
    color: var(--black-700);
    font-size: 16px;
    font-weight: 500;
}

.welcome-bx-img {
    position: absolute;
    width: 210px;
    height: 300px;
    right: 0;
    bottom: 0;
    object-position: center;
    object-fit: contain;
}

@media screen and (max-width:650px) {
    .welcome-bx-img {
        display: none;
    }
}

/* --- welcome box end --- */


/* --- Faqs Box start --- */
.faqs-info-bx {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.faqs-info-bx .faqs-info-item {
    cursor: pointer;
    color: var(--black-700);
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
}

.faqs-info-bx .faqs-info-item:hover {
    color: var(--black);
    text-decoration: underline;
}

/* --- Faqs Box end --- */


/* --- Social Share Icon & Social Icons start --- */
.social-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; */
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px;
}

.social-icon {
    padding-bottom: 0;
}

.social-icon .si-item {
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}

.social-icon .si-item .si-icon {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    font-size: 22px;
    color: white;
    background: #000;
    border: 1px solid #000;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
}

.social-icon .si-item .si-icon .fa {
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.social-icon .si-item:hover .si-icon {
    /* color: var(--white);
    background-color: var(--background-color1);
    border-color: var(--border-color1); */
    opacity: 1;
}

/* --- Social Share Icon & Social Icons start --- */


/* --- Challenge Form Box --- */
/* .challenge-form-box{
    max-width: 567px;
    width: 100%;
    margin: auto;
} */

.challenge-form-box>.common-box {
    max-width: 567px;
    width: 100%;
    margin: auto;
}

.challenge-steps-bx {
    margin-top: 15px;
    margin-bottom: 15px;
}

.challenge-steps-bx .csb-item,
.challenge-steps-bx {
    display: flex;
    justify-content: center;
    align-items: center;
}

.challenge-steps-bx .csb-item {
    position: relative;
    flex-direction: column;
    width: 106px;
}

.challenge-steps-bx .csb-item .csb-i-num {
    width: 40px;
    height: 40px;
    font-size: 16px;
    color: var(--black-700);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d5db;
    border-radius: 50%;
}

.challenge-steps-bx .csb-item .csb-i-label {
    color: var(--black-700);
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

.challenge-steps-bx .csb-item .csb-progress-bar {
    position: absolute;
    top: 50%;
    height: 7px;
    width: 25px;
    background-color: #d1d5db;
    transform: translateY(calc(-50% - 12px));
    overflow: hidden;
}

.challenge-steps-bx .csb-item .csb-progress-bar .csb-progress-inner {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #913515;
    opacity: 0;
}


.challenge-steps-bx .csb-item .csb-progress-bar.csb-p-left {
    left: 0;
    border-radius: 0px 5px 5px 0px;
}

.challenge-steps-bx .csb-item .csb-progress-bar.csb-p-left .csb-progress-inner {
    border-radius: 0;
}

.challenge-steps-bx .csb-item .csb-progress-bar.csb-p-right {
    right: 0;
    border-radius: 5px 0 0 5px;
}

.challenge-steps-bx .csb-item.complated .csb-i-num,
.challenge-steps-bx .csb-item.active .csb-i-num {
    color: white;
    background-image: linear-gradient(45deg, #f6a500, #913515);
}

.challenge-steps-bx .csb-item.complated .csb-i-label,
.challenge-steps-bx .csb-item.active .csb-i-label {
    color: var(--black);
}

.challenge-steps-bx .csb-item.complated .csb-progress-bar .csb-progress-inner,
.challenge-steps-bx .csb-item.active .csb-progress-bar .csb-progress-inner {
    opacity: 1;
}

.challenge-steps-bx .csb-item.complated .csb-progress-bar .csb-progress-inner {
    border-radius: 0;
}




.challenge-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.challenge-detail .cd-img {
    width: 70px;
}

.challenge-detail .cd-heading {
    color: var(--black);
    font-size: 24px;
    font-weight: 700;
    margin-top: 5px;
}

.challenge-detail .cd-pera {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
    -webkit-text-fill-color: #0000;
    background: linear-gradient(45deg, #f6a500, #913515);
    -webkit-background-clip: text;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 5px;
}

.challenge-detail .cd-more-info {
    font-size: 14px;
    font-weight: 600;
    color: var(--black-500);
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
}

.challenge-detail .cd-more-info a {
    color: var(--black);
    text-decoration: underline;
}

.challenge-detail .cd-more-info a:hover {
    color: #913515;
}

.challenge-detail .cd-amount {
    color: var(--black);
    font-size: 48px;
    font-weight: 800;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.challenge-detail .cd-amount span {
    position: relative;
    top: 10px;
    font-size: 20px;
    -webkit-text-fill-color: #0000;
    background: linear-gradient(45deg, #f6a500, #913515);
    -webkit-background-clip: text;
}

.cd-rules-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.cd-rules-info .cd-r-item {
    color: var(--black-700);
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
}

.cd-rules-info .cd-r-item svg {
    width: 28px;
    height: 28px;
    color: #913515;
    position: relative;
    top: 1px;
}

/*  */
.challenge-payment-flex {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.challenge-payment-flex .cpf-left {
    flex-basis: 460px;
    flex-shrink: 0;
}

.challenge-payment-flex .cpf-right {
    flex-basis: 390px;
    flex-shrink: 0;
}

@media screen and (max-width:900px) {
    .challenge-payment-flex {
        align-items: center;
        flex-direction: column-reverse;
    }

    .challenge-payment-flex .cpf-left {
        max-width: 576px;
        width: 100%;
        flex-basis: inherit;
        flex-shrink: inherit;
    }

    .challenge-payment-flex .cpf-right {
        max-width: 576px;
        width: 100%;
        flex-basis: inherit;
        flex-shrink: inherit;
    }
}


/* --- Payment tab bx start --- */
.payment-tab-bx {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.payment-tab-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
    border: 1px solid #d1d5db;
    background-color: var(--light-s-gray);
    padding: 8px 8px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.payment-tab-item .payment-img {
    width: 40px;
    height: 40px;
    padding: 3px;
    -o-object-position: center;
    object-position: center;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    margin-right: 10px;
    background-color: var(--white);
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
}

.payment-tab-item:hover {
    border-color: #f6a500;
    box-shadow: 0 0 0px 1px #f6a500;
}

.payment-tab-item.active {
    border-color: #f6a500;
    box-shadow: 0 0 0px 1px #f6a500;
    background-color: rgb(244 234 231);
}

.payment-tab-item.active .payment-img,
.payment-tab-item:hover .payment-img {
    opacity: 1;
}

/* --- Payment tab bx end --- */

/* --- Payment data bx start --- */
.payment-data-bx {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.payment-data-bx.pdb-style-2 {
    padding: 15px;
    border-radius: 10px;
    background-color: var(--light-s-gray);
}

.payment-data-bx.pdb-style-3 {
    padding: 15px;
    border-radius: 10px;
    background-color: var(--light-s-gray);
}

.payment-data-bx .pdb-item .pdb-i-lable {
    color: var(--black-700);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: -3px;
}

.payment-data-bx .pdb-item .pdb-i-data {
    font-size: 14px;
    color: var(--celeste);
    font-weight: 600;
    margin: 5px 0 0;
}

.payment-data-bx .pdb-item .pdb-i-data.pid-style-2 {
    position: relative;
    padding-right: 34px;
    width: fit-content;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: var(--celeste);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding: 6px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    top: -5px;
}

.payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon:hover {
    background-color: rgb(244 234 231);
    color: var(--black);
}

.payment-data-bx .pdb-item .pdb-i-data strong {
    color: var(--black);
    font-weight: 600;
}

.upi-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upi-item.pdb-item .pdb-i-lable {
    font-size: 14px;
}

.qr-code-box img {
    max-width: 200px;
    width: 100%;
    display: block;
    margin: auto;
}

.qr-code-box img:nth-child(2) {
    max-width: 320px;
}

/* --- Payment data bx end --- */


/*  */
.challenge-payment-details .cpd-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--black-100);
}

.challenge-payment-details .cpd-item:last-child {
    border-bottom: none;
}

.challenge-payment-details .cpd-item .cpd-title {
    color: var(--black-700);
    font-size: 16px;
    font-weight: 600;
}

.challenge-payment-details .cpd-item .cpd-amount {
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
}

.challenge-payment-details .cpd-item .cpd-amount.cpd-a-highlight {
    -webkit-text-fill-color: #0000;
    background: linear-gradient(45deg, #f6a500, #913515);
    -webkit-background-clip: text;
}

.challenge-payment-details .cpd-item.cpd-gt-item {
    padding: 0;
}

.challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    font-size: 24px;
    font-weight: 700;
}

.challenge-payment-details .cpd-item.cpd-gt-item .cpd-amount,
.challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    font-size: 24px;
}


/*  */
.payment-method-item {
    cursor: pointer;
    padding: 0px 10px 0px 0px;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background-color: var(--light-s-gray);
    display: flex;
    align-items: center;
    gap: 0px;
    transition: all 0.3s ease-in-out;
}

.payment-method-item .pmi-img {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    padding: 10px;
    object-position: center;
    object-fit: contain;
}

.payment-method-item .pmi-data-bx .pmi-heading {
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
}

.payment-method-item .pmi-data-bx .pmi-pera {
    color: var(--black-700);
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

.payment-method-item:hover {
    background-color: rgb(244 234 231);
    border-color: #f6a500;
    box-shadow: 0 0 0px 1px #f6a500;
}

.challenge-trading-rules {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: var(--light-s-gray);
}

.challenge-trading-rules .ctr-item {
    color: var(--black-700);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    gap: 5px;
}

.challenge-trading-rules .ctr-item a {
    text-decoration: underline;
    color: var(--black);
}

.challenge-trading-rules .ctr-item a:hover {
    color: #913515;
}

.challenge-trading-rules .ctr-item svg {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    color: #913515;
    position: relative;
    top: 1px;
}

/* --- Challenge Form Box --- */


/* --- Current Challenge Info Css Start --- */
.current-challenge-info {
    display: flex;
    gap: 15px;
}

.current-challenge-info .cci-left {
    flex-basis: 390px;
}

.current-challenge-info .cci-right {
    flex-grow: 1;
}

@media screen and (max-width:800px) {
    .current-challenge-info {
        flex-direction: column;
    }

}

/* --- Current Challenge Info Css End --- */

/* --- Add challenge btn start --- */
.add-challenge-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: relative;
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    background-color: rgb(244 234 231);
    border: 2px solid var(--black-100);
    padding: 30px 15px;
    transition: all 0.3s ease-in-out;
}

.add-challenge-btn svg {
    width: 24px;
    height: 24px;
    flex-basis: 24px;
}

.add-challenge-btn:hover {
    color: #913515;
    border-color: #f6a500;
}

/* --- Add challenge btn end --- */


/* --- Challenge Card Bx Start --- */
.challenge-card-bx {
    display: block;
    position: relative;
    border-radius: 12px;
    border: 2px solid var(--black-100);
    transition: border 0.3s ease-in-out;
}

.challenge-card-bx::before {
    content: "";
    position: absolute;
    display: block;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border-radius: inherit;
    transition: opacity .15s ease-in-out 0s;
    opacity: 0;
    background-image: linear-gradient(45deg, #f6a500, #913515);
}

.challenge-card-bx.active,
.challenge-card-bx:not(.notApproved):hover {
    border-color: rgba(0, 0, 0, 0);
}

.challenge-card-bx.active::before,
.challenge-card-bx:hover::before {
    opacity: 1;
}

.challenge-card-bx .ccb-inner {
    position: relative;
    z-index: 1;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--white);
}

.challenge-card-bx .challenge-top-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx {
    display: flex;
    align-items: center;
    gap: 10px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-img {
    width: 45px;
    height: 45px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-name {
    color: var(--black);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-type {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--black-700);
    font-size: 12px;
    font-weight: 500;
}

.challenge-card-bx .challenge-top-bx .ccb-info-bx .ccb-i-data .ccb-i-type span {
    display: block;
    border-radius: 50px;
    padding: 0px 10px;
    color: #913515;
    background-color: rgb(244 234 231);
}

.challenge-card-bx .challenge-top-bx .ccb-status {
    border-radius: 8px;
    background-color: var(--light-s-gray);
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
}

.challenge-card-bx .challenge-top-bx .ccb-status.ccb-status-yellow {
    background-color: #fdf6b2;
    color: #913515;
}

.challenge-card-bx .challenge-top-bx .ccb-status.ccb-status-green {
    background-color: #def7ec;
    color: #00ab61;
}

.challenge-card-bx .challenge-top-bx .ccb-status.ccb-status-red {
    background-color: #fde8e8;
    color: rgb(255, 0, 0)
}

.challenge-card-bx .challenge-trade-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 20px;
}

.challenge-card-bx .challenge-trade-info .cti-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.challenge-card-bx .challenge-trade-info .cti-item .cti-icon {
    width: 45px;
    height: 45px;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--light-s-gray);
}

.challenge-card-bx .challenge-trade-info .cti-item .cti-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.challenge-card-bx .challenge-trade-info .cti-data-bx .cti-label {
    color: var(--black-400);
    font-size: 12px;
    font-weight: 600;
}

.challenge-card-bx .challenge-trade-info .cti-data-bx .cti-num {
    color: var(--black);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
}

.challenge-card-bx .challenge-btns {
    display: none;
    justify-content: flex-end;
    gap: 10px;
    border-top: 2px solid var(--black-100);
    padding-top: 15px;
    margin-top: 15px;
}

.challenge-card-bx.active .challenge-btns {
    display: flex;
}


.challenge-card-bx {
    min-height: 137px;
}

.challenge-card-bx.notApproved::before {
    content: none;
}

.challenge-card-bx .ccb-notactive-bx {
    position: absolute;
    inset: 0;
    z-index: 2;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgb(244, 234, 231);
}

.challenge-card-bx .ccb-notactive-bx .ccb-notactive-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
}

/* --- Challenge Card Bx End --- */

/* --- Challenge Card Bx Start --- */
.challenge-credentials-bx {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #fdfdfd;
    border: 1px solid var(--black-100);
}

.challenge-credentials-bx .credential-item .credential-label {
    color: var(--black-400);
    font-size: 14px;
    font-weight: 600;
}

.challenge-credentials-bx .credential-item .credential-data {
    position: relative;
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    width: fit-content;
    padding-right: 35px;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon {
    align-items: center;
    border-radius: 50px;
    color: black;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    padding: 6px;
    position: absolute;
    right: 0;
    top: -5px;
    transition: all .3s ease-in-out;
    width: 30px;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.challenge-credentials-bx .credential-item .credential-data .credential-icon:hover {
    background-color: rgb(244 234 231);
    color: var(--black);
}

/* --- Challenge Card Bx End --- */


/* --- Account Overview Css Start --- */

.account-overview-item .aoi-label {
    color: var(--black-400);
    font-size: 14px;
    font-weight: 600;
}

.account-overview-item .aoi-data {
    color: var(--black);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
}

.account-overview-item .aoi-data.aoi-status-red {
    color: rgb(255, 0, 0);
}

.account-overview-item .aoi-data.aoi-status-green {
    color: #00ab61;
}

/* --- Account Overview Css End --- */


/* --- Chart Design Css Start--- */
.Mycharts {
    width: calc(100% + 34px);
    margin-top: -30px;
    position: relative;
    z-index: -1;
}

.Mycharts .MuiChartsLegend-series text {
    font-size: 12px !important;
}

.Mycharts .MuiChartsLegend-series .MuiChartsLegend-mark {
    width: 15px !important;
    height: 15px !important;
    transform: translate(5px, 3px);
}

.Mycharts .MuiChartsLegend-series text,
.Mycharts .MuiChartsAxis-label {
    font-family: 'Inter' !important;
    font-weight: 600 !important;
    fill: var(--black) !important;
}

.Mycharts .MuiChartsAxis-line,
.Mycharts .MuiChartsAxis-tick {
    stroke: #913515!important;
}


.Mycharts .MuiChartsAxis-tickLabel {
    fill: var(--black-700) !important;
    font-family: 'Inter' !important;
    font-weight: 600 !important;
}

/* --- Chart Design Css End --- */

/* --- Challenge passed Start Css --- */

.common-box.challange-passed.status-green2 {
    background-color: #def7ec;
}

.common-box .common-box-heading.status-green2,
.common-box .common-box-sub-heading.status-green2 {
    color: #00ab61;
}

/* --- Challenge passed End Css --- */

/* --- Challenge Detail Bx Start Css --- */
.account-detail-row {
    display: flex;
    gap: 15px;
}

.account-detail-row .adr-left {
    flex-grow: 1;
}

.account-detail-row .adr-right {
    flex-basis: 390px;
    flex-shrink: 0;
}

@media screen and (max-width:991px) {
    .account-detail-row {
        flex-direction: column;
    }

    .account-detail-row .adr-left {
        flex-grow: inherit;
    }

    .account-detail-row .adr-right {
        flex-basis: inherit;
        flex-shrink: inherit;
    }
}



/* --- --- */
.account-info-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 15px 0 0px;
}

.account-info-bx .aib-inner-bx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.account-info-bx .aib-inner-bx .aib-i-img {
    width: 45px;
    height: 45px;
}

.account-info-bx .aib-inner-bx .aib-i-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-name {
    color: var(--black);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
}

.account-info-bx .aib-inner-bx .aib-i-data .aib-i-created {
    color: var(--black-700);
    font-size: 12px;
    font-weight: 500;
}

.account-info-bx .aib-inner-bx .aib-status {
    border-radius: 8px;
    background-color: var(--light-s-gray);
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
}

.account-info-bx .aib-inner-bx .aib-status.aib-status-yellow {
    background-color: #fdf6b2;
    color: #913515;
}

.account-info-bx .aib-inner-bx .aib-status.aib-status-green {
    background-color: #def7ec;
    color: #00ab61;
}

.account-info-bx .aib-inner-bx .aib-status.aib-status-red {
    background-color: #fde8e8;
    color: rgb(255, 0, 0)
}

@media screen and (max-width:576px) {
    .account-info-bx {
        flex-direction: column;
        justify-content: inherit;
        align-items: inherit;
        gap: 15px;
    }

    /* .account-info-bx .common-btn-item{
        margin-left: auto;
    } */
}

/* --- */

/* --- */
.account-highlight {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.account-highlight .ah-item {
    display: flex;
    align-items: center;
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
}

.account-highlight .ah-item span {
    color: var(--black-400);
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
}

.account-highlight .ah-item svg {
    width: 20px;
    height: 20px;
    margin-left: 2px;
}

.account-highlight .ah-item.ah-status-green {
    color: #00ab61;
}

.account-highlight .ah-item.ah-status-red {
    color: rgb(255, 0, 0)
}

/* --- */

/*--start filter chart --*/

.dashbord-filter {
    margin: 20px auto 5px auto;
    display: block;
    text-align: center;
    background: transparent;
    padding: 0;
}

.dashbord-filter button {
    border: 1px solid #e5e7eb;
    padding: 10px 15px;
    background: transparent;
}

.dashbord-filter button:hover {  
    background: #f3f4f6;
    color: #913515;
}

.dashbord-filter .currency-filter .signinup-group select {
    background: #fff;
}

.dashbord-filter button.active {
    color: white;
    background: linear-gradient(45deg, #f6a500, #913515);
}

ul.filternav {
    margin: 20px 0;
    list-style: none;
    padding: 0;
}

.dashbord-filter button:first-child {
    border-radius: 10px 0 0 10px;
}

.dashbord-filter button:last-child {
    border-radius: 0 10px 10px 0;
}

.custom-date-picker {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.custom-date-picker .left input {
    border-radius: 8px 0 0 8px;
}

.custom-date-picker .right input {
    border-radius: 0 8px 8px 0;
}

.custom-date-picker input {
    padding: 8px 10px;
    border: 1px solid #cbd5e1;
    max-width: 160px;
    font-weight: 500;
    outline: none;
}


.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    color: white;
    background: linear-gradient(45deg, #f6a500, #913515);
}

.react-datepicker__day--keyboard-selected:hover, 
.react-datepicker__month-text--keyboard-selected:hover, 
.react-datepicker__quarter-text--keyboard-selected:hover, 
.react-datepicker__year-text--keyboard-selected:hover {
    color: white;
    background: linear-gradient(45deg, #f6a500, #913515);
}


@media screen and (max-width:576px) {
    .dashbord-filter {
        /* display: flex; */
        /* overflow: scroll; */
        justify-content: center;
    }

    .dashbord-filter button {
        font-size: 14px;
    }

    .custom-date-picker {
        margin-top: 20px;
    }

    .custom-date-picker input {
        max-width: 130px;
        text-align: center;
    }

    .disclaimer.legal-content ul.ul-red li {
        line-height: 22px;
    }

    .dashbord-filter.d-filter .signinup-group.sgl-icon select, 
    .dashbord-filter.d-filter .signinup-group.sgl-icon input {
        padding-left: 34px;
    }

    .Mycharts {
        pointer-events: none;
    }
}

/*--End filter chart--*/

/* --- Account basic info css start --- */
.account-basic-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.account-basic-info .abi-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.account-basic-info .abi-item .abi-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black-700);
    font-size: 16px;
    font-weight: 500;
}

.account-basic-info .abi-item .abi-label svg {
    color: black;
    width: 22px;
    height: 22px;
    margin-right: 5px;
}

.account-basic-info .abi-item .abi-data {
    color: var(--black);
    font-size: 16px;
    font-weight: 600;
}

.account-basic-info .abi-item .abi-data span {
    display: block;
    font-size: 14px;
    border-radius: 8px;
    padding: 4px 8px;
    color: #913515;
    background-color: rgb(244 234 231);
}

/* --- Account basic info css end --- */

/* --- Limit Card Bx Css Start  --- */
.limit-card-bx {
    position: relative;
    border: 1px solid var(--black-100);
    padding: 10px 15px;
    border-radius: 12px;
}

.limit-card-bx .lc-heading {
    font-size: 16px;
    font-weight: 700;
    -webkit-text-fill-color: #0000;
    background: linear-gradient(45deg, #f6a500, #913515);
    -webkit-background-clip: text;
    width: -moz-fit-content;
    width: fit-content;
}

.limit-card-bx .lc-time {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    background-color: #def7ec;
    color: #00ab61;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
}

.limit-card-bx .lc-time svg {
    width: 16px;
    height: 16px;
}

.limit-card-bx .limit-card-inner {
    /* padding-right: 50px; */
    margin-top: 10px;
    position: relative;
}

.limit-card-bx .limit-card-inner .lci-left-amount {
    color: var(--black);
    font-size: 18px;
    font-weight: 700;
}

.limit-card-bx .limit-card-inner .lci-left-amount span {
    font-size: 14px;
}

.limit-card-bx .limit-card-inner .lci-percent {
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    margin-top: -24px;
    margin-bottom: 10px;
    padding-right: 50px;
}

.limit-card-bx .limit-card-inner .common-progress-bar {
    width: calc(100% - 50px);
}

.limit-card-bx .limit-card-inner .lci-data-bx {
    margin-top: 10px;
    color: var(--black-600);
    font-size: 13px;
    font-weight: 500;
}

.limit-card-bx .limit-card-inner .lci-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 5px;
    width: 36px;
    height: 36px;
    padding: 6px;
    color: #913515;
    background-color: rgb(244 234 231);
    border-radius: 50%;
}

.limit-card-bx .limit-card-inner .lci-icon svg {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

/* --- Limit Card Bx Css End --- */

/* -- Average Data End --- */
.average-data {
    color: var(--black);
    margin-top: 34px;
    font-size: 20px;
    font-weight: 600;
}

.average-data.averag-status-red {
    color: rgb(255, 0, 0);
}

.average-data.averag-status-green {
    color: #00ab61;
}

@media screen and (max-width:991px) {
    .average-data {
        margin-top: 15px;
    }
}

/* -- Average Data Start --- */


/* --- Scale overview item start --- */
.scale-overview-item .soi-data-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}

.scale-overview-item .common-progress-bar {
    height: 3px;
    overflow: initial;
}

.scale-overview-item .soi-highlight {
    font-size: 16px;
    font-weight: 700;
    -webkit-text-fill-color: #0000;
    background: linear-gradient(45deg, #f6a500, #913515);
    -webkit-background-clip: text;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-top: 10px;
}

/* --- Scale overview item end --- */

/* --- Challenge Detail Bx End Css --- */

/* --- Payout request btn --- */
.payout-request-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 10px 0;
}

.payout-request-btn .prb-inner {
    width: 100%;
    max-width: 690px;
}

@media screen and (max-width:768px) {
    .payout-request-btn {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .payout-request-btn .prb-inner {
        max-width: 100%;
    }
}

/* --- Payout request btn --- */



/* --- best type bx --- */
.best-type-row {
    display: flex;
    gap: 25px;
}

.best-type-bx {
    position: relative;
    border-radius: 12px;
    flex-basis: 33.33%;
    background-color: rgb(244 234 231);
    padding: 25px;
    padding-right: 125px;
}

.best-type-bx .b-type-heading {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--black);
    font-size: 18px;
    font-weight: 600;
}

.best-type-bx .b-type-heading svg {
    width: 24px;
    height: 24px;
}

.best-type-bx .b-type-name {
    font-size: 24px;
    font-weight: 600;
    margin-top: 15px;
}

.best-type-bx .b-type-amount {
    color: var(--black-700);
    display: block;
    font-size: 14px;
    margin-top: 3px;
    font-weight: 600;
}

.best-type-bx .b-type-data {
    color: #00ab61;
    display: block;
    font-size: 18px;
    margin-top: 5px;
    font-weight: 600;
}

.best-type-bx .b-type-data span {
    font-weight: 500;
}

.best-type-bx .b-type-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width:1200px) {

    .best-type-bx {
        padding: 25px;
        text-align: center;
    }

    .best-type-bx .b-type-heading {
        margin-bottom: 10px;
        justify-content: center;
    }

    .best-type-bx .b-type-name {
        margin-top: 5px;
    }

    .best-type-bx .b-type-img {
        position: inherit;
        top: inherit;
        transform: inherit;
        right: inherit;
        width: 100px;
        height: 100px;
        object-fit: contain;
        object-position: center;
    }

}


@media screen and (max-width:810px) {

    .best-type-row {
        overflow: hidden;
        overflow-x: auto;
    }

    .best-type-bx {
        width: 243px;
        flex-shrink: 0;
        flex-basis: inherit;
    }

}

@media screen and (max-width:576px) {

    .best-type-bx {
        width: calc(100% - 50px);
    }

}

/* --- best type bx --- */


/* --- best today item --- */
.best-today-row {
    display: flex;
    gap: 25px;
}

.best-today-item {
    flex-basis: 33.33%;
    background-color: white;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid rgb(229 229 229)
}

.best-today-item:nth-child(1),
.best-today-item:nth-child(3) {
    margin-top: 30px;
}

.best-today-item .bti-num {
    font-size: 50px;
    font-weight: 700;
    text-align: end;
    line-height: normal;
}

.best-today-item .bti-num sup {
    font-size: 30px;
    font-weight: 600;
}

.best-today-item .bti-user-detail {
    display: flex;
    align-items: center;
    gap: 5px;
}

.best-today-item .bti-user-detail .bti-img-status {
    position: relative;
}

.best-today-item .bti-user-detail .bti-img-status .bti-status {
    text-transform: capitalize;
    position: absolute;
    bottom: 0;
    right: 5px;
    padding: 2px 5px;
    background-color: #def7ec;
    color: #00ab61;
    font-weight: 500;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid rgb(229 229 229);
}

.best-today-item .bti-user-detail .bti-img-status .bti-status.buy-status {
    background-color: #fde8e8;
    color: rgb(255, 0, 0);
}

.best-today-item .bti-user-detail .bti-img {
    width: 80px;
    height: 80px;
    object-position: center;
    object-fit: contain;
}

.best-today-item .bti-user-detail .bti-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--black);
}

.best-today-item .bti-user-detail .bti-tag {
    color: var(--black-700);
    display: block;
    font-size: 14px;
    margin-top: -3px;
    font-weight: 600;
}

.best-today-item .bti-othet-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    gap: 15px;
}

.best-today-item .bti-othet-data .bti-od-i-data {
    color: var(--black);
    font-size: 18px;
    font-weight: 600;
}

.best-today-item .bti-othet-data .bti-od-i-label {
    color: var(--black-400);
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-top: -1px;
}

.best-today-item .bti-othet-data .bti-od-i-data.bti-od-i-data-green {
    color: #00ab61;
    font-weight: 700;
    font-size: 20px;
}


.best-today-item.bti-1 {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 50%, rgba(228, 232, 43, 0.58) 100%, rgb(255, 255, 255) 100%);
}

.best-today-item.bti-1 .bti-num {
    color: #d4af37;
}

.best-today-item.bti-2 {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 50%, rgba(85, 207, 245, 0.4) 100%, rgb(255, 255, 255) 100%);
}

.best-today-item.bti-2 .bti-num {
    color: #44cff8;
}

.best-today-item.bti-3 {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 50%, rgba(67, 137, 34, 0.533) 100%, rgb(255, 255, 255) 100%);
}

.best-today-item.bti-3 .bti-num {
    color: #438922;
}


@media screen and (max-width:810px) {
    .best-today-row {
        overflow: hidden;
        overflow: auto;
    }

    .best-today-item {
        width: calc(100% - 50px);
        flex-shrink: 0;
        flex-basis: inherit;
    }


    .best-today-item:nth-child(1),
    .best-today-item:nth-child(3) {
        margin-top: inherit;
    }

    .best-today-item:nth-child(2) {
        order: 1;
    }

    .best-today-item:nth-child(1) {
        order: 2;
    }

    .best-today-item:nth-child(3) {
        order: 3;
    }
}

/* --- best today item --- */


/*--- Empty Box Css start ---*/
.empty-div-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    min-height: 190px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 30px 10px;
}

.empty-div-bx .empty-img {
    width: 70px;
    display: block;
    -webkit-filter: invert(1);
    filter: invert(1);
}

.empty-div-bx .empty-heading {
    line-height: normal;
    color: var(--celeste);
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-sub-heading {
    font-weight: 400;
    color: var(--celeste-600);
    font-size: 14px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .common-btn-item {
    margin-top: 5px;
}

@media screen and (max-width:576px) {
    .empty-div-bx .empty-heading {
        font-size: 16px;
    }

    .empty-div-bx .empty-sub-heading {
        font-size: 12px;
    }
}

/*--- Empty Box Css End---*/

/*--- Skeletant Css Start ---*/
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: var(--black);
    /* background-image: linear-gradient(45deg, #f6a500 30%, #b3664c 50%, #e0c793 70%); */
    background-position: left;
    background-repeat: repeat;
    background-size: 1200px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 1s infinite linear;
    pointer-events: none;
    border-radius: 15px;
    background-image: linear-gradient(45deg, #e5e7eb 30%, #fff 50%, #e5e7eb 70%);

}

.sh-47 {
    height: 47px;
}

.sh-95 {
    height: 95px;
}

.sh-171 {
    height: 171px;
}

.sh-215 {
    height: 215px;
}

.sh-245 {
    height: 245px;
}

.sh-532 {
    height: 532px;
}

@-webkit-keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}


/*--- Skeletant Css End ---*/


/* --- Calender summary css Start --- */

.calender-summary-row {
    display: flex;
    gap: 15px;


}

.calender-summary-row .csr-left {
    flex-grow: 1;
}

.calender-summary-row .csr-right {
    flex-basis: 500px;
    flex-shrink: 0;
}

.csr-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.csr-header .csr-btn {
    background: transparent;
    border: 1px solid #e5e7eb;
    padding: 3px 15px;
}

.csr-header .csr-btn:hover {
    background-color: #f3f4f6;
}

.csr-header .csr-btn:hover .csr-btn-inner svg {
    color: #c56e0a;
}

.csr-header span.csr-btn-inner .csr-header .csr-header-inner {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.csr-header span.csr-btn-inner {
    display: flex;
    font-size: 14px;
    width: 30px;
}

.csr-btn .csr-btn-inner svg {
    padding: 10px 5px;
}


.csr-btn:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
}

.csr-btn:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.rounded-xl {
    border-radius: 6px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
}

.csr-calender .csr-week {
    padding: 8px 12px;
    font-weight: 700;
}

.csr-calender .csr-date {
    padding: 8px 12px;
    background-color: #f3f4f6;
    margin: 4px;
    display: flex;
    flex-direction: column;
    text-align: right;
    border-radius: 6px;
    cursor: pointer;
}

.csr-calender .csr-date .csr-formatedate {
    font-weight: 700;
    color: #000000b3;
}

.csr-calender .csr-date:hover .csr-formatedate {
    color: #374151;
}

.csr-calender .csr-date .csr-event {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
}

.csr-calender .csr-date.opacity-50 {
    visibility: hidden;
}


/*  */
.csr-date.status-green2 {
    background-color: #def7ec;
    color: #00ab61;
}

.csr-date.status-green2 .csr-formatedate {
    color: #00ab61 !important;
}

/*  */
.csr-date.status-red2 {
    background-color: #fde8e8;
    color: rgb(255, 0, 0);
}

.csr-date.status-red2 .csr-formatedate {
    color: rgb(255, 0, 0) !important;
}

.csr-date.status-red2 .csr-event {
    color: rgb(255, 0, 0) !important;
}

.csr-date.status-green2 .csr-formatedate {
    color: #00ab61 !important;
}

.csr-date.status-green2 .csr-event {
    color: #00ab61 !important;
}

.csr-date:not(.selected-date):hover {
    background-color: rgb(244 234 231);
    color: var(--black) !important;
    border-color: rgb(244 234 231);
}

.csr-date:not(.selected-date):hover .csr-formatedate {
    color: var(--black) !important;
}

.csr-date.selected-date{
    border-color: #f6a500;
    box-shadow: 0 0 0px 2px #f6a500;
}


/* --- Calender summary css End--- */

/*--- summary for the day css start--- */

.calender-summary-section .css-inner {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 15px;
}

.calender-summary-section .css-inner .common-box.css-box {
    background-color: var(--light-s-gray);
    flex-basis: 48.63%;
}

.calender-summary-section .css-inner .common-box-sub-heading {
    color: #d68206;
    font-weight: 500;
}

.calender-summary-section .css-status {
    padding: 20px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
}

.h-460 {
    height: 460px;
}


@media screen and (max-width:991px) {
    .calender-summary-row {
        flex-direction: column;
    }

    .calender-summary-row .csr-left {
        flex-grow: inherit;
    }

    .calender-summary-row .csr-right {
        flex-basis: inherit;
        flex-shrink: inherit;
    }

    .h-460 {
        height: auto;
    }   
}

/*--- summary for the day css End--- */

/*--- notifaction alert box css Start--- */

.notifaction-alert-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.common-box.status-red2 {
    background-color: #fde8e8;
    padding: 20px 15px;
}

.notifaction-alert-box .nab-left svg {
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.notifaction-alert-box .nab-left span .nab-click {
    font-weight: 500;
    color: rgb(255, 0, 0);
    text-decoration: underline;
}

.notifaction-alert-box .nab-right .common-btn-item.cbi-fill {
    color: rgb(255, 0, 0);
    background-image: linear-gradient(45deg, #fbd5d5, #fbd5d5);
    border-color: #fbd5d5;
}

.common-box.status-yellow2 {
    background-color: #fdf6b2;
    color: #8e4b10;
}

@media screen and (max-width:767px) { 

    .nab-left span.nab-alert {
        display: block;
        padding-bottom: 5px;
    }
    .notifaction-alert-box {
        justify-content: start;
        flex-wrap: wrap;
        gap:10px;
    }

}

/*--- notifaction alert box css End--- */


/**affiliate section css start***/
.main-affiliate-section .mas-inner,
.main-affiliate-section .mas-inner.darkgray {
    background-color: rgb(244 234 231);;
    color: var(--black);
    padding: 20px 20px;
}

.main-affiliate-section .mas-inner .common-box-heading, 
.main-affiliate-section .mas-inner .common-box-sub-heading {
  color: var(--black);
}
.main-affiliate-section .mas-inner .common-box-sub-heading {
    font-weight: 400;
}
.main-affiliate-section .mas-inner .common-btn-item.cbi-fill {
    border-radius: 12px;
    padding: 6px 11px;
    height: auto;
    font-size: 16px;
    border: 2px solid var(--black-100);
    background: var(--white);
    color: #000;
    word-break: break-all;
    line-height: 25px;
    transition: all .3s ease-in-out;
}

.main-affiliate-section .mas-inner .common-btn-item.cbi-fill:hover {
    /* background: var(--light-s-gray); */
    border: 2px solid #933819;
}

.main-affiliate-section .mas-inner .common-btn-item.cbi-fill svg {
    margin-right: 0;
    width: 35px;
    height: 35px;
    padding: 6px;
}

.main-affiliate-section .mas-inner .common-btn-item.cbi-fill svg:hover {
    padding: 6px;
    background: #f4eae7;
    border-radius: 50px;
}

.main-affiliate-section .mas-inner .payout-request-btn .prb-inner {
    max-width: 100%;
    width: fit-content;
}

.main-affiliate-section .common-box.mas-aifiliate-work {
    background: #ffedc9;
}

.main-affiliate-section .common-box.mas-aifiliate-work .nab-alert{
    color: #913515;
}

.main-affiliate-section .common-box.mas-aifiliate-work .common-btn-item.cbi-fill {
    background-image: linear-gradient(45deg, #f6a500, #913515);
    color: white;
    border-color: #e18f04;
}

/*Total affiliate start */

.common-box.affiliate-box {
    background: none;
    border: 1px solid var(--black-100);
    padding: 30px 15px;
}


.total-affiliate {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.taf-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-basis: 450px;
}

.taf-left .payout-request {
    font-size: 14px;
    font-weight: 400;
}

.taf-left .payout-request span {
    color: #913515;
    text-decoration: underline;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.taf-left .payout-request span:hover {
    color: var(--black);
    transition: all .3s ease-in-out;
}

.taf-right { 
    flex-grow: 1;
}

.taf-section .taf-heading {
    color: var(--black);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 30px;
}

.taf-section .taf-count {
    color: #913515;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
}

@media screen and (max-width:767px) { 

    .main-affiliate-section .payout-request-btn {
        align-items: flex-start;
    }

    .taf-left {
        flex-direction: column;
        gap: 12px;
        flex-basis: 0;
    }

    .taf-section .taf-heading ,
    .taf-section .taf-count {
        font-size: 20px;
        line-height: 25px;
    }

    .common-box.affiliate-box {
        padding: 20px 15px;
    }

    .total-affiliate {
        flex-direction: column;
    }

    .mas-aifiliate-work .notifaction-alert-box {
        justify-content: space-between;
    }
}

/*Total affiliate End */

/* next payout start css*/

.affiliate-payout {
    margin: 30px 0;
}

.affiliate-payout .taf-left {
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
}

.affiliate-payout .total-affiliate {
    display: inherit;
}

.affiliate-payout .common-box.affiliate-box {
    padding: 20px 15px;
}
.affiliate-payout .error-msg {
    text-align: center;
    padding-bottom: 20px;
    color: red;
}

.affiliate-payout .taf-section .taf-heading ,
.affiliate-payout .taf-section .taf-count {
   font-size: 16px;
   padding-bottom: 15px;
}

.affiliate-payout .payout-request {
    text-align: right;
    font-size: 14px;
    font-weight: 300;
    padding-top: 5px;
}

@media screen and (max-width:767px) {  
    .affiliate-payout .taf-section .taf-heading, 
    .affiliate-payout .taf-section .taf-count {
        font-size: 14px;
        padding-bottom: 15px;
    }
}

/* next payout end css*/


/**affiliate section css End***/