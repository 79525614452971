/* --- Side Menu Bx Css Start --- */
.side-menu-bx{
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 124;
    width: 240px;
    height: calc(100% - 70px);
    background-color: var(--white);
    /* border-top: 1px solid var(--black-100); */
    transform: translateX(-100%);
    overflow: hidden;
    overflow-y: auto;
    padding: 16px 20px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.side-menu-bx.smb-mini{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 88px;
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
    overflow: inherit;
}

.side-menu-bx.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}

.side-menu-bx .smb-link{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    color: var(--black-700);
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    margin-bottom: 5px;
    height: 44px;
    transition: all 0.3s ease-in-out;
}

.side-menu-bx.smb-mini .smb-link{
    gap: initial;
    justify-content: center;
}

.side-menu-bx .smb-link .smb-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
}

.side-menu-bx .smb-link .smb-icon svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.side-menu-bx .smb-link .smb-tooltop{
    white-space: nowrap;
    position: absolute;
    left: calc(100% + 10px);
    background: black;
    color: white;
    padding: 10px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}


.side-menu-bx .smb-link:hover{
    background-color: var(--black-100);
    color: var(--black);
}

.side-menu-bx .smb-link:hover .smb-tooltop{
    opacity: 1;
    visibility: visible;
}

.side-menu-bx .smb-link.active{
    background-color: rgb(244 234 231);
    color: var(--black);
}

.side-menu-bx .smb-link.highlight-link{
    color: white;
    background: linear-gradient(45deg, #f6a500, #913515);
    background-size: 150%;
    background-position: left center;
}

.side-menu-bx .smb-link.highlight-link:hover{
    background-position: right center;
}

.side-menu-bx .smb-link-separator{
    margin: 10px 0;
    width: 100%;
    height: 1px;
    background-color: var(--black-100);
}
/* --- Side Menu Bx Css End --- */


/* --- Side Menu Overlay Start --- */
.side-menu-overlay{
    position: fixed;
    left: 0;
    top: 0;
    inset: 0;
    z-index: 123;
    background-color: var(--black-300);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.side-menu-overlay.active{
    opacity: 1;
    visibility: visible;
}
/* --- Side Menu Overlay End --- */

@media screen and (max-width:992px) {
    .side-menu-bx.smb-mini{
        display: none;
    }
}